import React from 'react'

import { Layout, Seo } from 'components'

const Page404 = () => {
  return (
    <Layout>
      <Seo />
      <div>Página não encontrada</div>
    </Layout>
  )
}

export default Page404
